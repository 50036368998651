@import '../../styles/aqua/config/color-palette.scss';
@import '../../styles/aqua/primitives/shadows.scss';

.top-panel-inpection-flatout{
    width: 100%;
    // height: 87px;
    top: 46px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    position: static;
    left: 50%;
    box-shadow: $shadow_layer_2;
    opacity: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    height: fit-content;

    .show-on-mobile {
        display: none;
    }
    .dont-show-on-mobile {
        display: inline-flex;
    }

    @media screen and (max-width: 860px) {
        .show-on-mobile {
            display: inline-flex;
        }
        .dont-show-on-mobile {
            display: none;
        }
    }

    &.hide {
        visibility: hidden;
        height: 0px;
    }
    .status-pill-panel {
        display: inline-flex;
        vertical-align: middle;
        margin-right: 8px;
        flex-direction: row;
    }

    // style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
    .status-badge {
        display: inline-block;
        align-items: center;
        justify-content: center;
        background: #ccc;
        border: 1px solid #666;
        cursor: help;
        border-radius: 36px;
        vertical-align: -1px;
        margin-left: 12px;

        &.acquired {
            background: #04ac1833;
            border: 1px solid #04ac18;
        }

        &.locked {
            background: #dd000033;
            border: 1px solid #dd0000;
        }

        &.analysing {
            background: #ffbb0033;
            border: 1px solid #ffbb00;
        }

        &-dot {
            width: 12px;
            height: 12px;
            background-color: #333;
            float: left;
            border-radius: 10px;
            vertical-align: middle;
            margin: 6px;

            &.analysing {
                background-color: #ffbb00;
            }

            &.acquired {
                background-color: #04ac18;
            }

            &.locked {
                background-color: #e90505;
            }
        }

        &-label {
            float: right;
            margin-top: 2px;
            margin-bottom: 0px;
            text-align: center;
            width: 72px;
            margin-right: 8px;
            font-size: small;
            font-weight: 400;
        }
    }
}

.slant {
    transform: rotateZ(45deg);
}
