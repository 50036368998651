@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    user-select: none;
    user-zoom: none;
    -webkit-user-drag: none;
    position: fixed;
    top: 0;
}
