// Fit To Screen
.fit-to-screen-icon {
  display: inline-block;
  width: 1.3rem;
  height: 1rem;
  background-image: url('/assets/icons/fit-to-screen.svg');
  background-position: center !important;
  background-repeat: no-repeat;
}

.spinner {
  &-16 {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('/assets/icons/spinner-16.svg');
    background-position: center !important;
    background-repeat: no-repeat;
  }
}

.flatout-logo {
  display: block;
  width: 96px;
  height: 96px;
  background-image: url('/assets/logo/rms-logo.svg');
  background-position: center !important;
  background-repeat: no-repeat;
}

.alcoa-logo-blue {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url('/assets/logo/alcoaLogoBlue.svg');
  background-position: center !important;
  background-repeat: no-repeat;
}

.slant {
  transform: rotateZ(45deg);
}

.tool-icon {
  width: 16px;
  height: 16px;
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: contain;

  &-transform {
    background-image: url('/assets/icons/transform.svg');
  }

  &-id-cell {
    background-image: url('/assets/icons/fill-cell.svg');
  }

  &-id-region {
    background-image: url('/assets/icons/fill-region.svg');
  }

  &-paint-cell {
    background-image: url('/assets/icons/fill-cell.svg');
  }

  &-paint-region {
    background-image: url('/assets/icons/fill-region.svg');
  }

  &-text {
    background-image: url('/assets/icons/text.svg');
  }

  &-scribble {
    background-image: url('/assets/icons/scribble.svg');
  }

  &-select {
    background-image: url('/assets/icons/select.svg');
  }

  &-delete {
    background-image: url('/assets/icons/delete2.svg');
  }

  &-erase {
    background-image: url('/assets/icons/delete.svg');
  }

  &-predict{
    background-image: url('/assets/icons/predict.svg');
  }

  &-undo{
    background-image: url('/assets/icons/undo.svg');
  }

  &-redo{
    background-image: url('/assets/icons/undo.svg');
    transform: scaleX(-1);
  }

}

