@import '../../styles/aqua/primitives/shadows.scss';
@import '../../styles/aqua/primitives/text.scss';
@import '../../styles/aqua/config/color-palette.scss';

.text-context-bar {
    background: #f3f3f3dd;
    border-radius: 8px;
    position: fixed;
    right: 8px;
    z-index: 4;
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition: transform 0.6s cubic-bezier(0.24, 1.57, 0.64, 0.99), opacity 0.5s ease-out, filter 0.6s ease-in;

    &.hide {
        opacity: 0;
        filter: blur(8px);
        transform: translateX(32px);
        pointer-events: none;
    }

    &-color-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;
    }
}

.format-button {
    &-bold > * {
        color: #222;
        font-weight: 800 !important;
        font-size: 20px !important;
    }

    &-italic > * {
        color: #222;
        font-style: italic !important;
        font-family: serif !important;
        font-size: 20px !important;
    }
}

.color-palette-button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: linear-gradient(135deg, #fff4, #fff2 50%, #fff0 51%, #fff2);
    box-shadow: inset 0 0 0 1px #0004;
    transform: scale(1);
    transition: transform 0.4s cubic-bezier(0, 0, 0, 1);
    cursor: default !important;

    &.active {
        transform: scale(1.2);
        background: linear-gradient(135deg, #fff4, #fff2 50%, #fff0 51%, #fff2), url(../../assets/icons/tick.svg);
        background-position: 45% 46%;
        background-repeat: no-repeat;
    }
}
