@import '../config/color-palette.scss';
@import '../primitives/surfaces.scss';

:root {
    .aq-headerbar {
        background: $primary;
        z-index: 100000;
        padding: 8px;
        max-height: 48px;

        * {
            vertical-align: middle;
        }

        &-env {
            font-size: 12px;
            color: rgb(241, 50, 50);
            font-weight: 600;
            margin-left: 4px;
        }

        .aq {
            &-avatar {
                user-select: none !important;
                
                &-container {
                    width: fit-content;
                    display: flex;
                    position: relative;
                    flex-direction: row;
                    align-content: center;
                    height: 100%;
                    flex-wrap: wrap;
                    float: right;
                    cursor: pointer;

                    .aq-avatar-profile-pic {
                        filter: brightness(1);
                    }

                    &:hover {
                        .aq-avatar-profile-pic {
                            filter: brightness(1.2);
                            box-shadow: 0 0 0 1px #fff, 0 0 0 6px rgba(255, 255, 255, 0.1333333333);
                        }

                        .aq-avatar-info-name {
                            color: rgba(255, 240, 150);
                        }
                    }
                }

                &-profile-pic {
                    border-radius: 50%;
                    overflow: hidden;
                    filter: brightness(1.2);
                    box-shadow: 0 0 0 1px #fff, 0 0 0 4px rgba(255, 255, 255, 0.1333333333);
                    transition: all 0.4s ease-in-out;
                }

                &-info {
                    display: inline-flex;
                    flex-direction: column;
                    color: white;
                    padding-left: 8px;

                    &-greeting {
                        font-size: smaller;
                    }

                    &-name {
                        font-size: x-small;
                        color: rgb(255, 230, 0);
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: x-small;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .title {
            color: white;
            font-size: 20px;
            margin-left: 8px;
            display: inline-flex;

            &-main {
                display: inline-block;
            }

            &-small {
                display: none;
            }

            @media screen and (max-width: 860px) {
                &-main {
                    display: none;
                }

                &-small {
                    display: inline-block;
                }
            }
        }

        .logo-small {
            display: inline-block;
            width: 28px;
            height: 100%;
            background: url(/assets/logo/alcoaLogoBlue.svg);
            filter: saturate(0) brightness(5);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .aq-profile-menu {
        @extend .aq-flyout;

        &::before {
            border-width: 0 !important;
            margin: 0 !important;
            height: auto;
            width: auto;
        }

        &::after {
            display: none;
        }
    }
}
