@import '../../styles/aqua/config/color-palette.scss';

.my-selector {
    min-width: 152px;
    user-select: none;

    &::after {
        content: unset !important;
    }

    &::before {
        border: none !important;
        width: 100%;
        height: 100%;
        left: 12px !important;
    }

    &-item {
        margin: 0px 12px;
        padding: 5px 8px;
        width: 71px;
        border: 1px solid #3330;
        border-radius: 4px;
        transition: all 0.4s ease-out;
        position: relative;
        text-align: center;
        cursor: default;

        &:hover {
            border: 1px solid #666;
        }

        &.active {
            background: $primary;
            color: $light_base;
            box-shadow: 0 4px 12px rgba($primary, 0.2);

            &.danger {
                background: $danger;
                box-shadow: 0 4px 12px rgba($danger, 0.2);
                color: $light_base !important;

                &.opti {
                    background: $warning;
                }
            }
        }

        &.danger {
            color: $danger;

            &:hover {
                border: 1px solid $danger;
            }
        }

        &.opti {
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                bottom: -1px;
                background: rgba($success, 0.2);
                border-right: 1px solid rgba($success, 0.25);
                border-left: 1px solid rgba($success, 0.25);
                z-index: -1;
            }
        }

        &.opti-start {
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                bottom: -1px;
                background: rgba($success, 0.2);
                border-radius: 4px 4px 0 0;
                border-top: 1px solid rgba($success, 0.25);
                border-right: 1px solid rgba($success, 0.25);
                border-left: 1px solid rgba($success, 0.25);
                z-index: -1;
            }
        }

        &.opti-end {
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                bottom: -1px;
                background: rgba($success, 0.2);
                border-radius: 0 0 4px 4px;
                border-bottom: 1px solid rgba($success, 0.25);
                border-right: 1px solid rgba($success, 0.25);
                border-left: 1px solid rgba($success, 0.25);
                z-index: -1;
            }
        }

        &.opti-single {
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                bottom: -1px;
                background: rgba($success, 0.2);
                border-radius: 4px !important;
                border-top: 1px solid rgba($success, 0.25) !important;
                border-bottom: 1px solid rgba($success, 0.25) !important;
                border-right: 1px solid rgba($success, 0.25);
                border-left: 1px solid rgba($success, 0.25);
                z-index: -1;
            }
        }

        &.outage {
            background-image: url(../../assets/icons/outage.svg) !important;
            background-repeat: no-repeat !important;
            background-position: 36px -2px !important;
            background-blend-mode: screen;
            text-align: left;
        }
    }

    .predict-confirm {
        .p-button-label {
            margin-left: -50px;
        }
    }

    .predict-confirm-icon {
        filter: saturate(0.7) brightness(5);
        margin-left: 38px;
    }
}