@import '../primitives/shadows.scss';

.aq {
    &-floating-panel {
        &-group {
            margin-top: 8px;
            border-radius: 8px;
            padding: 0;
            overflow: hidden;
            box-shadow: $shadow-layer-2;
            width: min-content;
            height: max-content;

            button {
                display: block;
                background: none;
                border-radius: 0 !important;
                border: none !important;
                width: 48px;
                min-width: 48px;
                max-width: 48px;
                height: 48px;
                min-height: 48px;
                max-height: 48px;
                outline: 0 !important;
                box-shadow: none !important;
            }

            button:hover {
                background: none !important;
                border: none !important;
                border-color: #0000 !important;
            }

            button:focus{
                background: none !important;
                border: none !important;
                border-color: #0000 !important;
            }

            @media (min-width: 1000px) {
                button {
                    width: 32px;
                    max-width: 32px;
                    min-width: 32px;
                    height: 32px;
                    min-height: 32px;
                }

                button:hover {
                    background: #fff4 !important;
                    border: none !important;
                    border-color: #0000 !important;
                }
            }
        }

        &.dark {
            .aq-floating-panel-group {
                background: #333;

                button {
                    filter: brightness(5);
                }
            }
        }
    }
}