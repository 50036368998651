@import '../config/color-palette.scss';

/* Text */
:root {
    .h,
    h {
        font-family: 'Roboto', 'Helvetica', sans-serif;

        &1 {
            font-weight: bold;
            font-size: 16px;
        }

        &2 {
            font-weight: 500;
            font-size: 14px;
        }

        &3 {
            font-weight: 500;
            font-size: 12px;
        }
    }

    .text {
        font-family: Roboto, 'Helvetica', sans-serif;
        font-weight: normal;

        &1 {
            font-size: 14px;
        }

        &2 {
            font-size: 12px;
        }
    }

    .accel-label {
        font-weight: bold;
        display: inline-block;
        font-size: small;
        padding: 2px 4px;
        background: #7772;
        border: 1px solid #fff5;
        border-radius: 4px;
        margin-left: 4px;
        height: fit-content;
        width: fit-content;
    }

    @media screen and (max-width: 1000px) {
        .accel-label {
            display: none;
        }
    }
}


.highlight {
    background: yellow;
    font-weight: 600;
}

.aq-formlabel {
    font-weight: 500;
    display: inline-block;
    font-size: 0.8rem;
    color:  $primary;
    font-family: "Roboto", sans-serif;
}

.text-primary{
    color: $primary;
}