@import '../config/color-palette.scss';
@import '../primitives/surfaces.scss';

:root {
    .aq-menu {
        @extend .aq-flyout;
        overflow: hidden;

        li[class*="item"] {
            background: none;

            *{
                color: #333 !important;
                background: none !important;
            }

            &:hover {
                background: rgba(0, 0, 0, 4%) !important;
            }
        }
    }
}