@import '../config/color-palette.scss';
@import '../primitives/surfaces.scss';
@import './textboxes.scss';

:root {
    select,
    .aq-select {
        @extend .aq-textbox;

        &:hover {
            background: darken($light_base, 4%);
        }
    }

    *[class*="dropdown"] {
        &[class*="panel"] {
            @extend .aq-flyout;
            background: $light_base;
            margin-top: 8px;
            overflow: hidden;

            li[class*="item"] {
                background: none;

                &:hover {
                    background: rgba(0, 0, 0, 4%) !important;
                }
            }
        }

        &[class*="text"] {
            font-size: 12px;
            height: 100%;
            padding: 7px;
        }
    }
}
