@import '../../styles/icons.scss';

.viewport {
  height: calc(100dvh - 148px);
  width: 100%;
  background-color: #e8e8e8;
  background-image: radial-gradient(circle at 4px 4px, #0004 2px, transparent 0);
  background-size: 32px 32px;
  background-position: 0 0;
  overflow: hidden;
  position: relative;
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;

  &.fullscreen {
    height: calc(100% - 52px);
  }

  &-loading-blocker {
    position: absolute;
    top: 0;
    left: 0;
    right: 4px;
    height: 100%;
    color: #666;
    box-shadow: inset 0 1px 0 #0004;
    background: #e8e8e866;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-weight: bold;
    pointer-events: all;
    user-select: none;

    &-logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      .graphic {
        width: 100%;
        text-align: center;
        font-size: 72px;
        color: #ccc;
        margin-bottom: 8px;
        text-shadow: 0 -1px 0 #aaa, 0 1px 0 #fff;
      }
    }
    

    &-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 0;
      margin-top: -8px;
      transition: all 0.4s cubic-bezier(0, 0, 0, 1);

      &.show {
        opacity: 1;
        margin-top: 0;
      }
    }

    &.opaque {
      background: #e8e8e8;
      backdrop-filter: blur(0);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #e8e8e800, #e8e8e8);
  }

  .canvas-container {
    position: relative;
    backface-visibility: hidden;
    width:fit-content;
    height: fit-content;
    transform: scale(1);
    box-shadow: 0 12px 24px #0008;
    pointer-events: all;
    z-index: 0;

    &.smooth-transform {
      transition: transform 0.3s cubic-bezier(0, 0, 0, 1);
    }

    .canvas {
      &-background {
        position: relative;
        top: 0;
        left: 0;
      }

      &-paint {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        mix-blend-mode: multiply;
      }

      &-preview {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &-floating-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    gap: 8px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 12px;
    width: 50vw;
    pointer-events: none;

    &-right {
      right: 0;
      align-items: flex-end;
      align-content: flex-end;
    }

    &-left {
      left: 0;
      align-items: flex-start;
      align-content: flex-start;
      z-index: 1;
    }

    * {
      pointer-events: auto;
    }
  }
}
