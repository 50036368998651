.orientation {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
  }
  
  .modified-text {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px !important;
  }
  
  .item-img {
    width: 40px;
    border-radius: 1px;
    border: 1px solid black;
  }
  
  