@import '../../styles/aqua/config/color-palette.scss';
@import '../../styles/aqua/primitives/shadows.scss';

.top-panel-maintence-scheduler{
    width: 100%;
    top: 46px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    position: static;
    left: 50%;
    box-shadow: $shadow_layer_2;
    opacity: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    height: fit-content;
}

.prediction {
    position: relative;

    &-zone {
        position: relative;
        padding-top: 32px;
        font-size: 1.6rem;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            box-shadow: inset 64px 0 64px white, inset -64px 0 64px white;
            pointer-events: none;
        }
    }

    &-overlay {
        display: flex;
        overflow-x: auto;
        position: relative;
        height: 64px;
    }
    
}