@import '../config/parameters.scss';
@import '../config/color-palette.scss';
@import './shadows.scss';

:root .aq {
    &-formcontrol {
        border-color: #0002;
        border: 1px solid #0002;
        border-bottom: 1px solid #0004;
        box-shadow: none;
        border-radius: $border_radius;

        &:hover {
            border: 1px solid #0002;
            border-bottom: 1px solid #0006;
        }

        &:focus {
            border-color: #0002;
            border: 1px solid #0002;
            border-bottom: 1px solid $primary;
        }

        &-dark {
            border: 1px solid rgba(#fff, 25%);
            border-bottom: 1px solid rgba(#000, 25%);
        }

        &:disabled {
            opacity: 0.5;
            filter: saturate(0.75);
        }
    }

    &-group {
        display: inline-flex;
        flex-direction: row;

        .aq-formcontrol{
            border-radius: 0;
            border-left: transparent;
            flex: auto;
            width: auto;

            &:first-child {
                border-radius: $border_radius 0 0 $border_radius;
                border-left: 1px solid #0002;
            }

            &:last-child {
                border-radius: 0 $border_radius $border_radius 0;
            }
        }
    }

    &-acrylic {
        background: rgba($light_base, 85%);
        backdrop-filter: blur(8px);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: url(/assets/images/white-noise.png);
            opacity: 0.5;
            filter: brightness(0.9);
            z-index: -1000;
        }
    }

    &-flyout {
        @extend .aq-acrylic;
        box-shadow: $shadow_layer_3;
        backface-visibility: hidden;
        border-radius: $border_radius * 2;
        outline: 1px solid rgba(0, 0, 0, 6%);
    }
}
