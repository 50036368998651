@import '../../styles/aqua/layouts/panels.scss';


.transform-controls {
    cursor: default;
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: all 0.5s 1s cubic-bezier(0, 0, 0, 1), opacity 0.2s 1s ease-in, filter 0.5s 1s ease-in;
}
.transform-controls.hidden {
    opacity: 0;
    filter: blur(8px);
    transform: translateX(-52px);
    pointer-events: none;
    transition: all 0.5s ease-in, opacity 0.5s ease-in, filter 0.5s ease-in;
}