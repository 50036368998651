.container {
    margin-left: 15%;
    margin-right: 15%;
    height: 100%;
    background-image: linear-gradient(90deg, #fff0, #fff, #fff0);
    text-align: center;
    color: #666;
  
    .error-image {
      height: 70vh;
      background-image: url(/assets/images/errorDoodles/404.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80vh;
    }
  }