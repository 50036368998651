.inspection-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-loading-blocker {
        position: absolute;
        top: 154px;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        background-color: #e8e8e8;
        display: grid;
        align-items: center;
        text-align: center;
        &-inner {
            font-size: small;
            font-weight: 800;
            color: #666;
            i,
            span {
                vertical-align: middle;
                display: inline-block;
            }

            span {
                margin-top: 1px;
            }
        }

        .graphic {
            width: 100%;
            text-align: center;
            font-size: 72px;
            color: #ccc;
            margin-bottom: 8px;
            text-shadow: 0 -1px 0 #aaa, 0 1px 0 #fff;
            &-label {
                display: inline-block;
                animation: animate-graphic-label 0.8s cubic-bezier(0, 0, 0, 1);
            }
        }
    }
}

@keyframes animate-graphic-label {
    from {
        opacity: 0;
        margin-top: -24px;
    }
    to {
        opacity: 1;
        margin-top: 0px;
    }
}

.unfullscreen {
    position: fixed;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;
    z-index: 10000;
}


.textbox-editor {
    position: absolute;
    transition: opacity 0.4s ease-out;
    z-index: 200;
    display: none;
    opacity: 0;

    &-input {
        /* font-size: 30px; */
        color: rgb(0, 0, 80);
        font-family: monospace !important;
        background: none !important;
        border-radius: 0 !important;
        border: none !important;
        // border-bottom: 1px dotted black !important;
        outline: 0 !important;
        box-shadow: none !important;
        width: 4000px;
        height: 72px !important;
        font-size: 36px;

        &:focus {
            border: none !important;
            // border-bottom: 1px dotted black !important;
            outline: 0 !important;
            box-shadow: none !important;
        }
    }
}