@import '../../styles/aqua/config/color-palette.scss';

.presentation-mode-switcher {
    display: flex;
    flex-direction: row;
    gap: 2px;
    background: #fff;
    border-radius: 4px;
    font-size: 12px;
    word-wrap: break-word;
    align-items: center;
    height: 32px;
    color: #333;
    border: 1px solid #0002;
    font-weight: 500;
    width: 100%;

    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-weight: 400;
        flex: auto;
        opacity: 0.6;
        border-radius: 4px;
        height: 100%;
        cursor: pointer;
        z-index: 0;

        transition: all 0.3s cubic-bezier(0, 0, 0, 1);

        &:hover {
            opacity: 1;
        }

        &.active {
            position: relative;
            opacity: 1;
            background: $primary;
            height: 38px;
            pointer-events: none;
            color: #fff;
            cursor: default;
            box-shadow: 0 4px 8px rgba($primary, 0.5);
            z-index: 1;
        }
    }
}