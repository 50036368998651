@import '../../styles/aqua/config/color-palette.scss';
@import '../../styles/aqua/primitives/shadows.scss';

.zonebox {
    gap: 10px;
    background: #f3f3f3dd;
    backdrop-filter: blur(4px);
    box-shadow: $shadow-layer-3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: max-content;
    overflow: hidden;

    animation: zonebox-animation 0.5s cubic-bezier(0, 0, 0, 1);
   
    &-item {
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        color: #6C757D;
        border-radius: 0px;
        padding: 8px;
        transition: all 0.3s ease-out;

        &.selected {
            color: #333;
            border-radius: 4px;
            background: #f3f3f3;
            box-shadow: 0 0 0 1px #3334, 0 4px 8px #0004;

            .floating-zonebox-item-name-dot {
                transform: scale(1.3);
            }
        }

        &-name {
            flex: 1;
            font-size: 0.8rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            &-dot {
                height: 14px;
                width: 14px;
                border-radius: 50%;
                margin-right: 10px;
                margin-top: -1px;
                box-shadow: inset 0 0 0 1px #0008;
                transform: scale(1);
                transition: transform 0.6s cubic-bezier(0.4, 0, 0, 1);
            }
        }

        &-btn {
            font-size: 16px;
            padding: 0px;
            width: 20px;
            height: 20px;
            // background: #ff8888;
            color: #ff8888;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            // box-shadow: 0px 3px 6px #00000029;
        }
    }
}

@keyframes zonebox-animation {
    0% {
        transform: translateX(100px);
        opacity: 0;
        filter: blur(8px);
    }
    50% {
        opacity: 0.75;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
    }
}