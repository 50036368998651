@import '../config/parameters.scss';
@import '../primitives/shadows.scss';
@import '../config/color-palette.scss';

$maskbg: #0001;

:root {
    .aq-dialog {
        border-radius: $border_radius * 3;
        background: $light_base;
        box-shadow: $shadow_layer_3;

        & > * {
            background: none;
        }

        & div[class*="header"] {
            color: $primary;

            & button {
                background: #33333308;
                border-radius: 4px;
                border: none !important;
                outline: 0 !important;
                box-shadow: none !important;
                backdrop-filter: blur(4px);
            }

            & button[class*="close"]:hover {
                background: $danger;
                color: #fff;

                * {
                    filter: brightness(5);
                }
            }
        }

        &-danger {
            & div[class*="header"] {
                @extend .aq-dialog-header-danger;
            }
        }

        &-header {
            &-danger {
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                color: #f00;
                background: repeating-linear-gradient(-45deg, #f000, #f000 12px, #ff00000a 12px, #ff00000a 25px);
                box-shadow: inset 0 -20px 20px #f8f8f8;
            }
        }

        & > div[class*="footer"] {
            @extend .aq-dialog-footer
        }

        &-footer {
            padding:1.5rem 1rem 1.5rem 1.5rem;
            background: #0001;
            border-bottom-right-radius: 12px !important;
            border-bottom-left-radius: 12px;
            box-shadow: inset 0 2px 2px #0000000f;
            display: flex;
            justify-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;

            & > * {
                width: 100%;
            }
        }
    }

    .aq-dialog-backdrop {
        backface-visibility: hidden;
        backdrop-filter: blur(8px) saturate(1.2);
        background-color: #0001 !important;
        transition: background-color 0.2s linear, backdrop-filter 0.2s linear !important;
    }
}