@import '../primitives/surfaces.scss';
@import '../config/color-palette.scss';
@import '../config/parameters.scss';

:root {
    /* Widgets */
    input[type=button],
    button,
    .aq-btn {
        @extend .aq-formcontrol;
        height: 32px;
        background: white;
        padding: 8px 12px;
        display: inline-flex;
        opacity: 1;
        color: $dark_base;
        font-family: Roboto, 'Helvetica', sans-serif;
        font-size: 12px;
        font-weight: normal;
        outline-offset: 0px;
        outline-color: #0000;
        cursor: default;

        *{
            color: $dark_base;
            font-weight: normal;
        }

        &.aq-btn-md {
            min-width: 100px;
        }

        &.aq-rounded {
            border-radius: 2rem;
        }

        &.aq-primary {
            background: $primary;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
            }
        }

        &.aq-secondary {
            background: $secondary;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
            }
        }

        &.aq-suggestive {
            background: $suggestive_action;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
            }
        }

        &.aq-destructive {
            background: $destructive_action;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
            }
        }
    }

    input[type=button],
    button:hover,
    .aq-btn:hover {
        background: darken(white, 4%);
        border: 1px solid #0002;
        border-bottom: 1px solid #0004;

        &.aq-primary {
            background: lighten($primary, 4%);
        }

        &.aq-secondary {
            background: lighten($secondary, 4%);
        }

        &.aq-suggestive {
            background: lighten($suggestive_action, 4%);
        }

        &.aq-destructive {
            background: lighten($destructive_action, 4%);
        }
    }

    input[type=button],
    button:active,
    .aq-btn:active {
        background: darken(white, 8%);
        border: 1px solid #0001;

        &.aq-primary {
            background: darken($primary, 8%);
        }

        &.aq-secondary {
            background: darken($secondary, 8%);
        }

        &.aq-suggestive {
            background: darken($suggestive_action, 8%);
        }

        &.aq-destructive {
            background: darken($destructive_action, 8%);
        }
    }
}
