@import '../../styles/aqua/config/color-palette.scss';

.cell-context-bar {
    background: #f3f3f3dd;
    border-radius: 4px;
    position: fixed;
    right: 8px;
    z-index: 4;
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    cursor: default !important;
    transition: transform 0.6s cubic-bezier(.24,1.57,.64,.99), opacity 0.5s ease-out, filter 0.6s ease-in;

    &-button {
        border-radius: 4px;
        &:hover {
            background: linear-gradient(90deg, #0000, #0001)
        }
    }

    &-thumb {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #0004;

        &.active {
            &::before {
                content: '';
                position: relative;
                top: 0;
                left: 0;
                width: 32px;
                height: 32px;
                display: block;
                background: url(../../assets/icons/tick.svg),  radial-gradient(#0008, #0000 60%);
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }
        }
    }

    &-thumb-container {
        overflow: hidden;
        height: 100%;
        transition: all 0.3s cubic-bezier(0, 0, 0, 1);
        
        &-inner {
            width: max-content;
            gap: 8px;
            margin: 4px;
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &.hide {
        opacity: 0;
        filter: blur(8px);
        transform: translateX(32px);
        pointer-events: none;
    }
}

.toolbox-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    animation: appear-from-blur 0.3s ease-out;

    &-damage-level {
        width: 48px;
        min-height: 200px;
        max-height: 400px;
        height: calc(100vh - 280px);
        padding-top: 16px;
        padding-bottom: 8px;
    }
    &-damage-rate{
        width: 48px;
        min-height: 200px;
        max-height: 238px;
        height: calc(100vh - 280px);
        padding-top: 16px;
        padding-bottom: 8px;
    }

    &-patterns {
        min-height: 200px;
        padding-bottom: 4px;
    }
}

@keyframes appear-from-blur {
    from {
        filter: blur(8px);
        opacity: 0;
    }
    to {
        filter: blur(0);
        opacity: 1;
    }
}

.type-header-button {
    width: 100% !important;
    overflow: hidden;

    & > *{
        font-weight: bold !important;
    }
}

.predict-button::before {
    content: '';
    position: absolute;
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba($suggestive_action, 0), rgba($suggestive_action, 0.4));
    animation: predict-button-fade 7s ease-out forwards, predict-button-pulse 1.5s ease-in infinite;
}

@keyframes predict-button-fade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes predict-button-pulse {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: 48px;
    }
}