@import '../../styles/aqua/config/color-palette.scss';

:root {
    .p-tree {
        padding: 0;


        &.highlight-nodes {
            .p-treenode-content {
                animation: pulse-content 0.7s ease-out infinite;
            }
        }

        &.highlight-internodes {
            .p-treenode-droppoint {
                animation: pulse-content 0.7s ease-out infinite;
            }
        }

        &node {
            font-style: normal !important;
            border-left: 8px solid $primary;

            &-content {
                box-shadow: 0 1px 0 #3336;
                background-color: #eee;
                border-radius: 0 !important;
            }

            &-dragover {
                background-color:rgb(253, 216, 93) !important;
            }

            &-droppoint-active {
                background-color:rgb(253, 216, 93) !important;
            }

            &-label {
                font-weight: 600;
            }

            &-leaf {
                border-left: 8px solid rgba($primary, 0.5);
                * {
                    font-weight: normal;
                    box-shadow: none !important;
                    background: none;
                    background-color: none !important;
                }
            }

            &-children {
                padding: 0;
            }
        }
    }
}

.zone-wizard {
    cursor: default !important;
    
    &-details {
        background: radial-gradient(
            circle,
            rgba(238, 238, 238, 1) 20%,
            rgba(255, 255, 255, 0) 30%,
            rgba(238, 238, 238, 1) 40%,
            rgba(255, 255, 255, 0) 50%
        );

        &-points {
            cursor: pointer;
        }
    }
}

@keyframes pulse-content {
    0% {
        background: rgb(255, 243, 205);
    }
    25% {
        background: rgb(253, 216, 93);
    }
    100% {
        background: rgb(255, 243, 205);
    }
}
