@import '../../styles/aqua/primitives/shadows.scss';
@import '../../styles/aqua/primitives/text.scss';
@import '../../styles/aqua/config/color-palette.scss';

.scribble-context-bar {
    background: #f3f3f3dd;
    border-radius: 4px;
    position: fixed;
    right: 8px;
    z-index: 4;
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    cursor: default;
    transition: transform 0.6s cubic-bezier(.24,1.57,.64,.99), opacity 0.5s ease-out, filter 0.6s ease-in;

    &.hide {
        opacity: 0;
        filter: blur(8px);
        transform: translateX(32px);
        pointer-events: none;
    }

    &-color-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;
    }
}
