.mask-passthrough {
    background: none !important;
    filter: none !important;
    backdrop-filter: none !important;
    pointer-events: none !important;

    & > * {
        pointer-events: all !important;
    }
}

.aq-dialog.passthrough {
    opacity: 0.5;
    filter: blur(8px);
    pointer-events: none !important;
    transition: all 0.2s linear;
}

:root {
    .check-change-tracker {
        transform: rotate(45deg) translateY(-6px);
        max-width: 14px;
        max-height: 14px;
        outline: 0 !important;
        box-shadow: none !important;

        .p-checkbox-box {
            max-width: 14px;
            max-height: 14px;
            border-radius: 0;
            border: 2px solid rgb(255, 174, 0);
            background: none;
            outline: 0 !important;
            box-shadow: none !important;
            border-color: rgb(255, 174, 0);

            &:hover {
                border: 2px solid rgb(255, 174, 0) !important;
                background: rgba(228, 160, 13, 0.2) !important;
            }

            &:focus {
                border: 2px solid rgb(255, 174, 0) !important;
                outline: 0 !important;
                box-shadow: none !important;
            }

            & > * {
                display: none;
            }
        }

        .p-focus {
            border: 2px solid rgb(255, 174, 0) !important;
            outline: 0 !important;
            box-shadow: none !important;
        }

        .p-highlight {
            background: rgb(255, 174, 0);

            &:hover {
                background: rgb(255, 208, 0) !important;
            }
        }
    }
}

.prop-control-table {
    td:first-child {
        font-weight: 600;
        text-align: end;
        padding-right: 12px;
    }
    td:last-child {
        padding-left: 12px;
    }
    td {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
