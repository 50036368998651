.container {
    margin-left: 15%;
    margin-right: 15%;
    height: 100%;
    background-image: linear-gradient(90deg, #fff0, #fff, #fff0);
    text-align: center;
    color: #666;

    .access-error-image {
        height: 70vh;
        background-image: url(/assets/images/errorDoodles/403.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80vh;
       
    }
    @media screen and (max-width: 768px) {
      .access-error-image {
        background-image: url(/assets/images/errorDoodles/403_mobile.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
  }
   
}
