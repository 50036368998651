@import '../config/color-palette.scss';
@import '../config/parameters.scss';

:root {
    .aq {
        &-nav {
            background: linear-gradient(darken($primary, 0.1), rgba(darken($primary, 0.1), 0.75));
            padding: 8px;
            backface-visibility: hidden;
            backdrop-filter: blur(16px);
            border-right: 1px solid #0002;
            display: flex;
            flex-direction: column;
            width: fit-content;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            transform: translateX(0);
            border-radius: 0 $border_radius * 2 $border_radius * 2 0;
            box-shadow: 16px 0 32px #0004, inset 2px 0 0 $primary, inset 0 0 0 1px #fff2, inset 0 0 64px $primary;
            transition: transform 0.3s cubic-bezier(0, 0, 0, 1);
            z-index: 10000;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: url(/assets/images/white-noise.png);
                opacity: 0.2;
                filter: brightness(0.4);  
                z-index: -1;
            }

            &-backdrop {
                position: fixed;
                z-index: 9999;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                pointer-events: none;
                background: #0000;
                transition: background 0.4s cubic-bezier(0, 0, 0, 1);

                &.active {
                    pointer-events: all;
                    background: #0002;
                }
            }

            nav {
                margin-top: 48px;
            }

            &.hide {
                transform: translateX(-$sidebar_width);
                transition: transform 0.2s ease-in;

                div[class*='current'] {
                    transform: translateX($sidebar_width);
                    transition: transform 0.2s ease-in;
                }

                nav {
                    pointer-events: none;
                }
            }

            &-current {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: fixed;
                top: 9px;
                left: 8px;
                width: max-content;
                transform: translateX(0);

                transition: transform 0.3s cubic-bezier(0, 0, 0, 1);

                &-btn {
                    background: none;
                    border: none !important;
                    color: $light_base;
                    outline: 0 !important;
                    box-shadow: none;

                    * {
                        color: $light_base;
                    }

                    &:hover {
                        background: rgba(255, 255, 255, 7%);
                        box-shadow: 0 1px 0 darken($primary, 5%), 0 -1px 0 #ffffff15;
                    }

                    &:focus {
                        border: none !important;
                    }

                    &:active {
                        background: rgba(255, 255, 255, 4%);
                    }

                    &.animate * {
                        animation: shrink-horizontal 0.5s cubic-bezier(0, 0, 0, 1);
                    }

                    @keyframes shrink-horizontal {
                        0% {
                            transform: scaleX(1);
                        }

                        50% {
                            transform: scaleX(0.6);
                        }

                        100% {
                            transform: scaleX(1);
                        }
                    }
                }

                &-title {
                    color: white;
                    font-size: 1rem;
                    margin-left: 8px;

                    &.animate{
                        backface-visibility: hidden;
                        animation: animate-title 1s cubic-bezier(0, 0, 0, 1);
                    }

                    @keyframes animate-title {
                        from {
                            opacity: 0;
                            margin-left: 0;
                        }
                        to {
                            opacity: 1;
                            margin-left: 8px;
                        }
                    }
                }
            }

            a {
                @extend .aq-btn;
                color: $light_base !important;
                background: none;
                border: none !important;
                text-decoration: none;
                min-width: 200px;
                margin-bottom: 8px;
                margin-left: -3px;
                display: flex;
                flex-direction: row;
                align-items: center;

                * {
                    color: $light_base !important;
                }

                &::before {
                    content: '';
                    position: relative;
                    left: -11px;
                    top: 0;
                    width: 3px;
                    height: 2px;
                    border-radius: $border_radius;
                    background: lighten($primary, 50%);
                    opacity: 0;
                    transition: all 0.4s cubic-bezier(0, 0, 0, 1);
                }

                &:hover {
                    background: rgba(255, 255, 255, 7%);
                }

                &.active {
                    background: rgba(255, 255, 255, 10%);
                    border: none !important;
                    text-decoration: none !important;

                    &::before {
                        opacity: 1;
                        height: 20px;
                    }
                }
            }

            &-icon {
                margin-right: 22px;
                vertical-align: middle;
            }
        }
    }
}
