@import '../../styles/aqua/config/color-palette.scss';

.splash-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background : $primary;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-out;

    &.show {
        opacity: 1;
    }

    &-logo {
        width: 12vw;
        height: 12vw;
        max-width: 256px;
        max-height: 256px;
        min-width: 64px;
        min-height: 64px;
        display: inline-block;
        background: url(../../assets/logo/alcoaLogo.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }

    &-inner {
        text-align: center;
        width: 100%;
    }
}


.aq-spinner-dark {
    transform: translateY(calc(25vh - 64px));
}