.upload-templates{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-container{
        width:100%;
        width: 100%;
        height: calc(100% - 154px); // height of titlebar + dropdown bar
        // background-color: blue;
        // background-image: radial-gradient();
    }
}