// @import '~antd/dist/antd.css';
.custom-dropdown {
    label {
        vertical-align: middle;
        height: 32px;
        padding-top: 5px;
        font-weight: 500;
        float: left;
        color: #005491;
        font-family: 'Roboto',sans-serif;
    }

    div {
        float: right;
        width: 100%;
    }
}
