@import '../../styles/aqua/primitives/shadows.scss';
@import '../../styles/aqua/config/color-palette.scss';

.alert-container {
    text-align: center;
    width: 300px;
    border-radius: 4px;
    box-shadow: $shadow-layer-1, $shadow-layer-2;
    z-index: 1501;
    background-color: white;
}

.backdrop {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: #0001;
    backdrop-filter: blur(4px);
}

.alert-content {
    margin-left: 8px;
    margin-right: 8px;
    max-height: 200px;
    overflow-y: auto;
    animation: reveal_content 0.2s ease-out;

    h3 {
        color: #333;
        margin-top: 8px;
        font-size: large;
        margin-bottom: 0 !important;
    }
}

.lower-box {
    text-align: center;
    border-radius: 0 0 4px 4px;
    animation: reveal_box 0.2s ease-out;
}

.triangle {
    width: 0 !important;
    height: 0 !important;
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-top: 8px solid #fff !important;
    margin: auto !important;
    animation: reveal_triangle 0.2s ease-out !important;
}

.success {
    background: $success;
}

.error {
    background: $danger;
}

.warning {
    background: $warning;
}

.main-button {
    border: none !important;
    outline: 0 !important;
    font-size: 10px !important;
    font-weight: bolder !important;
    color: #fff !important;
    background-color: #00000059 !important;
    text-align: center !important;
    justify-content: center;
    padding: 6px !important;
    margin-top: 10px !important;
    margin-bottom: 18px !important;
    animation: reveal_button 1s ease-in-out !important;
    transition: all 0.2s ease-out !important;
    cursor: pointer !important;

    * {
        filter: brightness(5);
    }
}

.main-button:hover {
    background-color: #0000007a !important;
}

@keyframes reveal_box {
    25% {
        height: 0px;
    }

    100% {
        height: 54px;
    }
}

@keyframes reveal_button {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes reveal_triangle {
    0% {
        border-top: 0px solid #fff;
    }

    100% {
        border-top: 8px solid #fff;
    }
}

@keyframes reveal_content {
    0% {
        margin-top: -15px;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    100% {
        margin-top: 0;
    }
}

// .mainBtn {
//   border: none;
//   outline: 0;
//   font-size: 10px;
//   font-family: 'Segoe UI';
//   font-weight: bolder;
//   color: #fff;
//   background-color: #00000059;
//   text-align: center;
//   padding: 6px;
//   width: 9em;
//   border-radius: 4px;
//   margin: 10px;
//   margin-bottom: 18px;
//   /* animation: reveal_button 1s ease-in-out; */
//   transition: all 0.2s ease-out;
// }

// .mainBtn:hover {
//   background-color: #0000007a;
// }

.main-button-container {
    display: flex;
    align-items: center;
    vertical-align: center;
    z-index: 30;
}

.alert-icon {
    margin-top: 16px;
    &-success {
        color: $success;
    }

    &-warning {
        color: $warning;
    }

    &-danger {
        color: $danger;
    }
}