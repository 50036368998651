@import '../../styles/aqua/primitives/shadows.scss';
@import '../../styles/aqua/config/color-palette.scss';
@import '../../styles/aqua/primitives/text.scss';

.toolbox {
    background: #f3f3f3dd;
    backdrop-filter: blur(4px);
    box-shadow: $shadow-layer-3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: max-content;
    overflow: hidden;
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: transform 0.5s cubic-bezier(1, 0, 0, 1), opacity 0.2s ease-in, filter 0.5s ease-in;

    &.disabled {
        opacity: 0;
        transform: translateX(-52px);
        pointer-events: none;
        filter: blur(8px);
        transition: all 0.5s ease-in, opacity 0.5s ease-in, filter 0.5s ease-in;

        & > * {
            pointer-events: none;
        }
    }

    button {
        width: 48px;
        min-width: 48px;
        height: 45px;
        background: none;
        border: none;
        border-radius: 0px;

        &:hover {
            background: #3331 !important;
            border: none !important;
        }

        &:focus {
            border: none !important;
            border-color: #0000 !important;
            outline: none !important;
            outline-color: #0000 !important;
            box-shadow: none !important;
        }

        &.active {
            background: $primary;

            * {
                color: #fff;
                filter: contrast(0) brightness(5);
            }

            &:hover {
                background: lighten($color: $primary, $amount: 10%) !important;
            }

            &.delete {
                background: $danger;

                &:hover {
                    background: lighten($color: $danger, $amount: 10%) !important;
                }
            }
        }
    }
}