@import '../../styles/aqua/config/color-palette.scss';

.top-panel-upload-templates{
    width: 100%;
    top: 46px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    position: static;
    left: 50%;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    height: fit-content;

    .status-pill-panel {
        display: inline-flex;
        vertical-align: middle;
        margin-right: 8px;
        flex-direction: row;
    }
    
    .status-badge {
        display: inline-block;
        align-items: center;
        cursor: help;
        justify-content: center;
        background: #ccc;
        border: 1px solid #666;
        border-radius: 36px;
        vertical-align: -1px;

        &.published {
            background: #04ac1833;
            border: 1px solid #04ac18;
        }

        &.invalid {
            background: #dd000033;
            border: 1px solid #dd0000;
        }

        &.analysing {
            background: #ffbb0033;
            border: 1px solid #ffbb00;
        }

        &-dot {
            width: 12px;
            height: 12px;
            background-color: #333;
            float: left;
            border-radius: 10px;
            vertical-align: middle;
            margin: 6px;

            &.analysing {
                background-color: #ffbb00;
            }

            &.published {
                background-color: #04ac18;
            }
        }

        &-label {
            float: right;
            margin-top: 2px;
            margin-bottom: 0px;
            text-align: center;
            width: 72px;
            margin-right: 8px;
            font-size: small;
            font-weight: 400;
        }
    }
}